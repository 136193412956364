"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
import { runTrim } from "project/general/js/trimHelper.js";
export default class HeroBigComponent extends DcBaseComponent {
  static getNamespace() {
    return "hero-big";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "pagination"];
  }
  onInit() {
    var _a, _b, _c, _d;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) === 1) {
      this.element.classList.add("is-single");
    }
    if (((_d = (_c = this.refs) == null ? void 0 : _c.slides) == null ? void 0 : _d.length) <= 1)
      return;
    this.mountCarousel();
    this.trimHeader();
  }
  mountCarousel() {
    const that = this;
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Pagination, Autoplay, EffectFade],
      slidesPerView: 1,
      lazy: true,
      autoplay: {
        disableOnInteraction: true,
        pauseOnMouseEnter: true
      },
      pagination: {
        el: this.refs.pagination,
        clickable: true,
        renderBullet: function(index, className) {
          return '<span class="' + className + '"><svg viewBox="0 0 40 40"><circle cx="20" cy="20" r="18"></circle></svg>' + (index + 1) + "</span>";
        }
      },
      on: {
        autoplayTimeLeft(s, time, progress) {
          const progressCircle = that.element.querySelector(
            ".swiper-pagination-bullet-active svg"
          );
          progressCircle.style.setProperty("--progress", 1 - progress);
        },
        activeIndexChange(s) {
          if (s.activeIndex === 0) {
            const bullets = that.element.querySelectorAll(
              ".swiper-pagination-bullet svg"
            );
            bullets.forEach((bullet) => {
              bullet.style.setProperty("--progress", 0);
            });
          }
        },
        autoplayStop() {
          that.element.classList.add("without-autoplay");
        }
      }
    });
  }
  trimHeader() {
    var _a, _b;
    this.firstHeader = (_b = (_a = this.refs) == null ? void 0 : _a.title) == null ? void 0 : _b[0];
    if (this.firstHeader) {
      this.stri = this.firstHeader.innerHTML;
      this.firstHeader.innerText = "";
      runTrim(this.stri, this.firstHeader);
    }
  }
  onDestroy() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
  }
}
