"use strict";
import React from "react";
import { getMedia, getSrc } from "./helpers";
import { defaultMediaProperties } from "./default-sizes";
export const ReactImage = ({ imageUrl, alt, className = "", focalX = "", focalY = "", mediaProperties = defaultMediaProperties }) => React.createElement(
  React.Fragment,
  null,
  React.createElement(
    "picture",
    null,
    mediaProperties == null ? void 0 : mediaProperties.map((property, index) => {
      const srcSet = getSrc(imageUrl, property.imageWidth, property.imageHeight, focalX, focalY, property.quality, false, property == null ? void 0 : property.rmode);
      return React.createElement("source", { key: imageUrl + index, "data-srcset": srcSet, srcSet, media: getMedia(property.minWidth, property.maxWidth) });
    }),
    mediaProperties == null ? void 0 : mediaProperties.map((property, index) => {
      const srcSet = getSrc(imageUrl, property.imageWidth, property.imageHeight, focalX, focalY, property.quality, true, property == null ? void 0 : property.rmode);
      return React.createElement("source", { key: "webp" + imageUrl + index, "data-srcset": srcSet, srcSet, media: getMedia(property.minWidth, property.maxWidth) });
    }),
    React.createElement("img", { className: `${className} lazyload`, "data-splide-lazy": imageUrl, "data-src": imageUrl, alt: alt != null ? alt : "" })
  )
);
