"use strict";
import React, { useContext, useState, useCallback, useEffect, useRef } from "react";
import SearchSuggestionsForm from "components/search-suggestions/js/search-suggestions.form";
import SearchSuggestionsResults from "components/search-suggestions/js/search-suggestions.results";
import { useSearchSuggestions } from "components/search-suggestions/api/useSearchSuggestions";
import { SearchSuggestionsContext } from "components/search-suggestions/js/search-suggestions.context";
import { debounce } from "lodash";
import { useModalEffect } from "shared/hooks/useModalEffect";
import { useDeviceObserver } from "shared/hooks/useDeviceObserver";
const SEARCH_DEBOUNCE_INTERVAL_MS = 300;
const SearchSuggestionsModal = (props) => {
  const [isActive, setActive] = useState(false);
  const [isModalAnimate, setModalAnimate] = useState(true);
  const [query, setQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const modalRef = useRef(null);
  const { showResultsButtonText, showResultsButtonLink } = useContext(SearchSuggestionsContext);
  const onOpenSearch = () => {
    setActive(true);
  };
  const onCloseSearch = () => setActive(false);
  const { data, isFetching } = useSearchSuggestions({ query });
  const debouncedSetQuery = useCallback(debounce((value) => setQuery(value), SEARCH_DEBOUNCE_INTERVAL_MS), []);
  useEffect(() => {
    return () => {
      debouncedSetQuery.cancel();
    };
  }, [debouncedSetQuery]);
  useEffect(() => {
    props.searchButton.addEventListener("click", onOpenSearch);
  }, []);
  useDeviceObserver({
    onMobile: () => setModalAnimate(true),
    onDesktop: () => setModalAnimate(false)
  });
  useModalEffect({
    modalRef,
    isActive,
    isModalAnimate,
    closeModalCb: onCloseSearch
  });
  const renderShowResults = () => {
    return React.createElement(
      "div",
      { className: "search-suggestions__footer" },
      React.createElement("a", { href: `${showResultsButtonLink}?query=${query}`, className: "search-suggestions__link button button--lime button--arrow-right" }, showResultsButtonText)
    );
  };
  return React.createElement(
    "search",
    { className: "modal search-suggestions__modal", ref: modalRef },
    React.createElement(
      "div",
      { className: `search-suggestions__content modal__content ${!query && "search-suggestions__content-empty"}` },
      React.createElement(
        "button",
        { className: "modal__close", type: "button", onClick: onCloseSearch },
        React.createElement("span", { className: "visually-hidden" }, "Close modal")
      ),
      React.createElement(SearchSuggestionsForm, { searchValue, setQuery: debouncedSetQuery, setSearchValue, isFocus: isActive }),
      isFetching ? React.createElement(
        "div",
        { className: "loader" },
        React.createElement("div", null)
      ) : React.createElement(
        React.Fragment,
        null,
        React.createElement(
          "div",
          { className: "ss-results" },
          React.createElement(SearchSuggestionsResults, { query, data }),
          query && !!(data == null ? void 0 : data.length) && React.createElement("div", { className: "search-suggestions__footer-mobile" }, renderShowResults())
        ),
        query && !!(data == null ? void 0 : data.length) && React.createElement("div", { className: "search-suggestions__footer-desktop" }, renderShowResults())
      )
    )
  );
};
export default SearchSuggestionsModal;
