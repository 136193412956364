"use strict";
import { useEffect } from "react";
import { deviceObserver } from "project/general/js/device";
const useDeviceObserver = ({ onMobile, onDesktop }) => {
  useEffect(() => {
    const handleDeviceChange = () => {
      if (deviceObserver.is("<", "desktop")) {
        onMobile();
      } else {
        onDesktop();
      }
    };
    handleDeviceChange();
    deviceObserver.subscribeOnChange(handleDeviceChange);
  }, []);
};
export { useDeviceObserver };
