"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Pagination } from "swiper/modules";
import Swiper from "swiper";
import { deviceObserver } from "project/general/js/device";
import "swiper/css";
export default class CtaPanel extends DcBaseComponent {
  static getNamespace() {
    return "cta-panel";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "pagination"];
  }
  onInit() {
    var _a, _b;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) <= 1)
      return;
    this._addListeners();
    if (deviceObserver.is(">", "tablet"))
      return;
    this._mountCarousel();
  }
  _addListeners() {
    deviceObserver.subscribeOnChange((newDevice, oldDevice) => {
      if (deviceObserver.is("<", "desktop")) {
        this._mountCarousel();
        return;
      }
      this._unMountCarousel();
    });
  }
  _mountCarousel() {
    this.swiperWrapper = document.createElement("div");
    this.swiperWrapper.classList.add("swiper-wrapper");
    this.refs.slides.forEach((slide) => {
      slide.classList.add("swiper-slide");
      this.swiperWrapper.appendChild(slide);
    });
    this.refs.carousel.prepend(this.swiperWrapper);
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Pagination],
      slidesPerView: 1.1,
      spaceBetween: 24,
      lazy: true,
      pagination: {
        el: this.refs.pagination,
        clickable: true
      }
    });
  }
  _unMountCarousel() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
    this.refs.slides.forEach((slide) => {
      this.refs.carousel.appendChild(slide);
    });
    this.swiperWrapper.remove();
  }
  onDestroy() {
    this._unMountCarousel();
  }
}
