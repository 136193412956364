"use strict";
function runTrim(stri, headerEl) {
  let s = trimByPixel(stri, headerEl.getBoundingClientRect().width).trim();
  let str = stri.replace(s, "");
  let spanLine = document.createElement("span");
  spanLine.innerHTML = s.replace("<br>", " ");
  headerEl.appendChild(spanLine);
  if (str.trim().length > 0) {
    runTrim(str, headerEl);
  }
  headerEl.classList.add("is-visible");
}
function trimByPixel(str, width) {
  let spn = document.createElement("span");
  spn.classList.add("title-line");
  spn.innerText = str;
  document.body.appendChild(spn);
  let txt = str;
  if (spn.getBoundingClientRect().width <= width) {
    spn.remove();
    return txt;
  }
  while (!(spn.getBoundingClientRect().width <= width && (txt.slice(-1) === " " || txt.slice(-4) === "<br>")) && !(txt.indexOf(" ") === -1 && txt.indexOf("<br>") === -1)) {
    txt = txt.slice(0, -1);
    spn.innerText = txt;
  }
  spn.remove();
  return txt;
}
export { runTrim };
