"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
const ACCORDION_DELAY_MS = 100;
export default class Footer extends DcBaseComponent {
  static getNamespace() {
    return "footer";
  }
  static getRequiredRefs() {
    return ["navItems"];
  }
  onInit() {
    this._addListeners();
  }
  _addListeners() {
    this.refs.navItems.forEach((navItem) => {
      const accordionList = navItem.querySelector(
        ".footer__navigation-col-list"
      );
      if (!accordionList)
        return;
      const accordionButton = navItem.querySelector(
        ".footer__navigation-col-button"
      );
      accordionButton.addEventListener("click", () => {
        accordionButton.classList.toggle("active");
        const isActive = accordionButton.classList.contains("active");
        setTimeout(() => {
          if (isActive) {
            accordionList.style.maxHeight = accordionList.scrollHeight + "px";
          } else {
            accordionList.style.maxHeight = null;
          }
        }, ACCORDION_DELAY_MS);
      });
    });
  }
}
