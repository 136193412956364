"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { youtubeLoader } from "components/embedded-video/js/youtube-loader";
export default class EmbeddedVideoComponent extends DcBaseComponent {
  constructor(...args) {
    super(...args);
    __publicField(this, "onYouTubeIframeAPIReady", () => {
      youtubeLoader.onReady(() => {
        var _a;
        if (!this.options.videoId)
          return;
        (_a = window == null ? void 0 : window.YT) == null ? void 0 : _a.ready(() => {
          this.player = new window.YT.Player(this.refs.player, {
            height: "100%",
            width: "100%",
            videoId: this.options.videoId,
            playerVars: {
              rel: 0
            },
            events: {
              onReady: () => {
                this.isPlayerReady = true;
              }
            }
          });
        });
      });
    });
    __publicField(this, "playVideo", () => {
      if (!this.player || !this.isPlayerReady)
        return;
      this.player.playVideo();
      this.refs.previewImage.classList.add("hide");
    });
    this.player = null;
    this.isPlayerReady = false;
  }
  static getNamespace() {
    return "embedded-video";
  }
  static getRequiredRefs() {
    return ["previewImage", "player", "button"];
  }
  onInit() {
    this.onYouTubeIframeAPIReady();
    this.refs.button.addEventListener("click", this.playVideo);
  }
  onDestroy() {
    if (this.player) {
      this.player.stopVideo();
      this.player.destroy();
      this.player = null;
    }
    this.refs.button.removeEventListener("click", this.playVideo);
  }
}
