"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { Navigation } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
class ArticleCarouselComponent extends DcBaseComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "onSeeMoreButtonClick", () => {
      var _a, _b, _c;
      if ((_a = this.refs) == null ? void 0 : _a.list.classList.contains("show-all")) {
        (_b = this.refs) == null ? void 0 : _b.list.classList.remove("show-all");
        this.refs.seeMoreButton.innerHTML = `
                <svg class="icon" width="24" height="24">
                    <use xlink:href="#icon-plus"></use>
                </svg>
                See more`;
      } else {
        (_c = this.refs) == null ? void 0 : _c.list.classList.add("show-all");
        this.refs.seeMoreButton.innerHTML = `
                <svg class="icon" width="14" height="14">
                    <use xlink:href="#icon-minus"></use>
                </svg>
                See less`;
      }
    });
  }
  static getNamespace() {
    return "article-carousel";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "next", "prev", "list"];
  }
  onInit() {
    var _a, _b, _c, _d, _e;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) === 1) {
      this.element.classList.add("is-single");
    }
    if (((_d = (_c = this.refs) == null ? void 0 : _c.slides) == null ? void 0 : _d.length) <= 1)
      return;
    this.mountCarousel();
    if ((_e = this.refs) == null ? void 0 : _e.seeMoreButton)
      this.refs.seeMoreButton.addEventListener(
        "click",
        this.onSeeMoreButtonClick
      );
  }
  mountCarousel() {
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 32,
      lazy: true,
      navigation: {
        nextEl: this.refs.next,
        prevEl: this.refs.prev
      }
    });
  }
  onDestroy() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
  }
}
export default ArticleCarouselComponent;
