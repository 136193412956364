"use strict";
export const $ArticleListingItemViewModel = {
  type: "object",
  properties: {
    name: {
      type: "string",
      nullable: true
    },
    image: {
      $ref: "#/components/schemas/ImageViewModel"
    },
    url: {
      type: "string",
      nullable: true
    },
    duration: {
      type: "string",
      nullable: true
    },
    difficulty: {
      type: "string",
      nullable: true
    }
  },
  additionalProperties: false
};
export const $ColourViewModel = {
  type: "object",
  properties: {
    id: {
      type: "string",
      format: "uuid"
    },
    title: {
      type: "string",
      nullable: true
    },
    red: {
      type: "integer",
      format: "int32"
    },
    green: {
      type: "integer",
      format: "int32"
    },
    blue: {
      type: "integer",
      format: "int32"
    },
    hexCode: {
      type: "string",
      readOnly: true
    }
  },
  additionalProperties: false
};
export const $ImageViewModel = {
  type: "object",
  properties: {
    src: {
      type: "string"
    },
    version: {
      type: "string"
    },
    alt: {
      type: "string",
      nullable: true
    }
  },
  additionalProperties: false
};
export const $KeyValuePair_2 = {
  type: "object",
  properties: {
    key: {
      type: "string"
    },
    value: {
      type: "string"
    }
  },
  additionalProperties: false
};
export const $LocationViewModel = {
  type: "object",
  properties: {
    id: {
      type: "integer",
      format: "int32"
    },
    name: {
      type: "string",
      nullable: true
    },
    latitude: {
      type: "number",
      format: "double"
    },
    longitude: {
      type: "number",
      format: "double"
    },
    distance: {
      type: "number",
      format: "double"
    },
    addressLine1: {
      type: "string",
      nullable: true
    },
    addressLine2: {
      type: "string",
      nullable: true
    },
    addressLine3: {
      type: "string",
      nullable: true
    },
    city: {
      type: "string",
      nullable: true
    },
    postcode: {
      type: "string",
      nullable: true
    },
    country: {
      type: "string",
      nullable: true
    },
    websiteUrl: {
      type: "string",
      nullable: true
    },
    phone: {
      type: "string",
      nullable: true
    },
    email: {
      type: "string",
      nullable: true
    },
    directionsUrl: {
      type: "string",
      nullable: true
    },
    openingHours: {
      type: "array",
      items: {
        $ref: "#/components/schemas/KeyValuePair`2"
      }
    }
  },
  additionalProperties: false
};
export const $ProductCompareItemViewModel = {
  type: "object",
  properties: {
    keyInformation: {
      type: "array",
      items: {
        type: "string"
      },
      nullable: true
    },
    description: {
      type: "string",
      nullable: true
    }
  },
  additionalProperties: false
};
export const $ProductFeatureViewModel = {
  type: "object",
  properties: {
    info: {
      type: "string"
    }
  },
  additionalProperties: false
};
export const $ProductListingCardViewModel = {
  type: "object",
  properties: {
    name: {
      type: "string"
    },
    url: {
      type: "string"
    },
    id: {
      type: "integer",
      format: "int32"
    },
    description: {
      type: "string",
      nullable: true
    },
    images: {
      type: "array",
      items: {
        $ref: "#/components/schemas/ImageViewModel"
      }
    },
    features: {
      type: "array",
      items: {
        $ref: "#/components/schemas/ProductFeatureViewModel"
      },
      nullable: true
    },
    colours: {
      type: "array",
      items: {
        $ref: "#/components/schemas/ColourViewModel"
      },
      nullable: true
    }
  },
  additionalProperties: false
};
