"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Modal } from "components/modal/js/modal";
const IMG_DESKTOP_QUERY = "&quality=90&width=1028&height=1326";
const IMG_MOBILE_QUERY = "&quality=90&width=690&height=470";
const PRODUCT_IMG_DESKTOP_QUERY = "&quality=90&width=608&height=608";
const PRODUCT_IMG_MOBILE_QUERY = "&quality=90&width=496&height=496";
const FORMAT_WEBP_QUERY = "&format=webp";
export default class InspectModal extends DcBaseComponent {
  static getNamespace() {
    return "inspect-modal";
  }
  static getRequiredRefs() {
    return ["image"];
  }
  onInit() {
    if (!this.refs.products)
      return;
    this.products = JSON.parse(
      this.refs.products.getAttribute("data-products")
    );
    this._addListeners();
    this.modalNode = null;
  }
  _addListeners() {
    this.refs.openModal.addEventListener(
      "click",
      this._handleOpenModalClick.bind(this)
    );
  }
  _handleOpenModalClick() {
    if (!this.modalNode) {
      this._createInspectModal();
    }
    this._onOpenModal();
  }
  _onOpenModal() {
    this.modal.open();
  }
  _createInspectModal() {
    const template = document.querySelector("#inspect-modal");
    const modalContent = template.content.cloneNode(true);
    const productImage = this._copyFormatMainImage();
    modalContent.querySelector(".inspect-modal__image").appendChild(productImage);
    const modalWrapper = document.createElement("div");
    modalWrapper.classList.add(
      "modal",
      "modal--white-bg-mobile",
      "inspect-modal"
    );
    modalWrapper.appendChild(modalContent);
    this.modalNode = document.body.appendChild(modalWrapper);
    this.modal = new Modal({
      modalDomNode: this.modalNode,
      isAnimated: false
    });
    this._createProductItemsList();
    this.closeModalButton = this.modalNode.querySelector(".modal__close");
    this.closeModalButton.addEventListener("click", () => this.modal.close());
  }
  _copyFormatMainImage() {
    const productImage = this.refs.image;
    const src = productImage.querySelector("img").getAttribute("src");
    const image = this._getImageObject({
      src,
      desktopQuery: IMG_DESKTOP_QUERY,
      mobileQuery: IMG_MOBILE_QUERY
    });
    return this._createPictureElement(image);
  }
  _createPictureElement(image) {
    const pictureElement = document.createElement("picture");
    const imgElement = document.createElement("img");
    image.sources.forEach((source) => {
      const sourceElement = document.createElement("source");
      if (source.media)
        sourceElement.setAttribute("media", source.media);
      if (source.type)
        sourceElement.setAttribute("type", source.type);
      sourceElement.setAttribute("srcset", source.src);
      pictureElement.appendChild(sourceElement);
    });
    imgElement.setAttribute("src", image.sources[0].src.split("?")[0]);
    pictureElement.appendChild(imgElement);
    return pictureElement;
  }
  _createProductItemsList() {
    const productList = this.modalNode.querySelector(
      ".inspect-modal__products-list"
    );
    this.products.forEach((product) => {
      var _a;
      const listElement = document.createElement("li");
      listElement.appendChild(this._createProductItemElement(product));
      if ((_a = product.colour) == null ? void 0 : _a.hexCode) {
        listElement.style.setProperty(
          "--product-bg-color",
          product.colour.hexCode
        );
      }
      productList.appendChild(listElement);
    });
  }
  _createProductImg({ src, version, alt }) {
    const image = this._getImageObject({
      src,
      desktopQuery: PRODUCT_IMG_DESKTOP_QUERY,
      mobileQuery: PRODUCT_IMG_MOBILE_QUERY,
      version
    });
    return this._createPictureElement(image);
  }
  _getImageObject({ src, desktopQuery, mobileQuery, version }) {
    const isIncludesQuery = src.includes("?");
    const baseSrc = (isIncludesQuery ? src : src + "?") + (version ? `&v=${version}` : "");
    return {
      sources: [
        {
          type: "image/webp",
          media: "(min-width: 1025px)",
          src: baseSrc + FORMAT_WEBP_QUERY + desktopQuery
        },
        {
          type: null,
          media: "(min-width: 1025px)",
          src: baseSrc + desktopQuery
        },
        {
          type: "image/webp",
          media: "(max-width: 1024px)",
          src: baseSrc + FORMAT_WEBP_QUERY + mobileQuery
        },
        {
          type: null,
          media: "(max-width: 1024px)",
          src: baseSrc + mobileQuery
        }
      ]
    };
  }
  _createProductItemElement({ name, colour, image, url }) {
    const template = document.querySelector("#inspect-product-item");
    const itemContent = template.content.cloneNode(true);
    const productImg = this._createProductImg(image);
    const productLink = document.createElement("a");
    productLink.textContent = name;
    productLink.setAttribute("href", url);
    itemContent.querySelector(".inspect-modal__product-name").appendChild(productLink);
    itemContent.querySelector(".inspect-modal__product-img").appendChild(productImg);
    if (colour == null ? void 0 : colour.title) {
      itemContent.querySelector(".inspect-modal__product-color").textContent = colour.title;
    }
    return itemContent;
  }
}
