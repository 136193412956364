"use strict";
import React, { useMemo } from "react";
const highlightText = (text, highlight) => {
  if (!highlight || !text)
    return text;
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, index) => part.toLowerCase() === highlight.toLowerCase() ? React.createElement("span", { key: `${part}-${index}`, className: "highlight" }, part) : part);
};
export const SearchItemTitle = (props) => {
  const highlightTitleMemoized = useMemo(() => {
    return highlightText(props.name || "", props.query);
  }, [props.name, props.query]);
  return React.createElement("div", { className: "ss-item__title" }, highlightTitleMemoized);
};
