"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useQuery } from "@tanstack/react-query";
import { getApiClient } from "api";
export const useSearchSuggestions = (data) => {
  var _a;
  const apiClient = getApiClient();
  return useQuery({
    queryKey: ["suggestions", data.query],
    queryFn: () => __async(void 0, null, function* () {
      const response = yield apiClient.v1.getUmbracoApiSearchSuggestions({
        query: data.query,
        pageSize: 10
      });
      return response;
    }),
    enabled: ((_a = data.query) == null ? void 0 : _a.trim()) !== ""
  });
};
