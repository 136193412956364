"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { Interceptors } from "./core/OpenAPI";
import { AxiosHttpRequest } from "./core/AxiosHttpRequest";
import { V1Service } from "./services.gen";
export class ApiClient {
  constructor(config, HttpRequest = AxiosHttpRequest) {
    __publicField(this, "v1");
    __publicField(this, "request");
    var _a, _b, _c, _d, _e, _f, _g, _h;
    this.request = new HttpRequest({
      BASE: (_a = config == null ? void 0 : config.BASE) != null ? _a : "",
      VERSION: (_b = config == null ? void 0 : config.VERSION) != null ? _b : "Latest",
      WITH_CREDENTIALS: (_c = config == null ? void 0 : config.WITH_CREDENTIALS) != null ? _c : false,
      CREDENTIALS: (_d = config == null ? void 0 : config.CREDENTIALS) != null ? _d : "include",
      TOKEN: config == null ? void 0 : config.TOKEN,
      USERNAME: config == null ? void 0 : config.USERNAME,
      PASSWORD: config == null ? void 0 : config.PASSWORD,
      HEADERS: config == null ? void 0 : config.HEADERS,
      ENCODE_PATH: config == null ? void 0 : config.ENCODE_PATH,
      interceptors: {
        request: (_f = (_e = config == null ? void 0 : config.interceptors) == null ? void 0 : _e.request) != null ? _f : new Interceptors(),
        response: (_h = (_g = config == null ? void 0 : config.interceptors) == null ? void 0 : _g.response) != null ? _h : new Interceptors()
      }
    });
    this.v1 = new V1Service(this.request);
  }
}
