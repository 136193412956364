"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { Navigation, Pagination } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
export default class ContentCarouselComponent extends DcBaseComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "_onScroll", () => {
      if (this.element.classList.contains("is-visible"))
        return;
      if (this.isInViewport()) {
        this.expand();
      }
    });
    __publicField(this, "isInViewport", () => {
      const rect = this.element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight);
    });
  }
  static getNamespace() {
    return "content-carousel";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "next", "prev", "pagination"];
  }
  onInit() {
    var _a, _b, _c, _d;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) === 1) {
      this.element.classList.add("is-single");
    }
    if (((_d = (_c = this.refs) == null ? void 0 : _c.slides) == null ? void 0 : _d.length) <= 1)
      return;
    this.mountCarousel();
    if (this.isInViewport())
      this.expand();
    document.addEventListener("scroll", this._onScroll);
  }
  mountCarousel() {
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      lazy: true,
      navigation: {
        nextEl: this.refs.next,
        prevEl: this.refs.prev
      },
      pagination: {
        el: this.refs.pagination,
        clickable: true
      }
    });
  }
  expand() {
    this.element.style.height = `${this.element.scrollHeight}px`;
    this.element.classList.add("is-visible");
    document.removeEventListener("scroll", this._onScroll);
    setTimeout(() => {
      this.element.style.height = "auto";
    }, 1e3);
  }
  onDestroy() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
  }
}
