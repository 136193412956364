"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { runTrim } from "project/general/js/trimHelper.js";
export default class HeroStandardComponent extends DcBaseComponent {
  static getNamespace() {
    return "hero-standard";
  }
  onInit() {
    var _a;
    if (!((_a = this.refs) == null ? void 0 : _a.title))
      return;
    this.stri = this.refs.title.innerHTML;
    this.refs.title.innerText = "";
    runTrim(this.stri, this.refs.title);
  }
}
