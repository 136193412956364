"use strict";
function focusAndOpenKeyboard(el, timeout) {
  if (!timeout) {
    timeout = 100;
  }
  if (el) {
    var __tempEl__ = document.createElement("input");
    __tempEl__.style.position = "absolute";
    __tempEl__.style.top = el.offsetTop + 7 + "px";
    __tempEl__.style.left = el.offsetLeft + "px";
    __tempEl__.style.height = 0;
    __tempEl__.style.opacity = 0;
    document.body.appendChild(__tempEl__);
    __tempEl__.focus();
    setTimeout(() => {
      el.focus();
      el.click();
      document.body.removeChild(__tempEl__);
    }, timeout);
  }
}
export { focusAndOpenKeyboard };
