"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const _Modal = class {
  constructor({ modalDomNode, closeCb = () => {
  }, isAnimated = true }) {
    __publicField(this, "onOverlayClick", (event) => {
      const modalContent = this.modalDomNode.querySelector(".modal__content");
      if (!modalContent)
        return;
      if (!modalContent.contains(event.target)) {
        this.close();
      }
    });
    __publicField(this, "onEscKeyPress", (event) => {
      if (event.key === "Escape") {
        const isOpen = this.modalDomNode.classList.contains("open");
        if (isOpen) {
          this.close();
        }
      }
    });
    if (!modalDomNode)
      throw new Error("Modal DOM node is required");
    this.modalDomNode = modalDomNode;
    this.closeCb = closeCb;
    this.isAnimated = isAnimated;
  }
  open() {
    this.modalDomNode.style.display = "flex";
    document.documentElement.classList.add("scroll-locked");
    setTimeout(() => {
      this.modalDomNode.classList.add("open");
    }, 0);
    this.modalDomNode.addEventListener("click", this.onOverlayClick);
    document.addEventListener("keydown", this.onEscKeyPress);
  }
  close() {
    this.modalDomNode.classList.add("close");
    setTimeout(
      () => {
        this.modalDomNode.style.display = "none";
        this.modalDomNode.classList.remove("open");
        this.modalDomNode.classList.remove("close");
        document.documentElement.classList.remove("scroll-locked");
      },
      this.isAnimated ? _Modal.MODAL_ANIMATION_DURATION_MS : 0
    );
    if (this.closeCb)
      this == null ? void 0 : this.closeCb();
    this.modalDomNode.removeEventListener("click", this.onOverlayClick);
    document.removeEventListener("keydown", this.onEscKeyPress);
  }
};
let Modal = _Modal;
__publicField(Modal, "MODAL_ANIMATION_DURATION_MS", 750);
export { Modal };
