"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Modal } from "components/modal/js/modal";
export default class SignupForm extends DcBaseComponent {
  static getNamespace() {
    return "signup";
  }
  static getRequiredRefs() {
    return ["formModal", "openModal", "closeModal"];
  }
  onInit() {
    this._addListeners();
    this.modal = new Modal({
      modalDomNode: this.refs.formModal
    });
  }
  _addListeners() {
    this.refs.openModal.addEventListener("click", () => {
      this.modal.open();
    });
    this.refs.closeModal.addEventListener("click", () => {
      this.modal.close();
    });
  }
}
