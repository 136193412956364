"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
class ProductCarouselComponent extends DcBaseComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "onFilterChange", (e) => {
      if (!this.refs.select)
        return;
      const filter = this.refs.select.value;
      this.noResults = false;
      if (filter.length > 0) {
        this.refs.slides.forEach((item) => {
          const tags = JSON.parse(item.getAttribute("data-tags"));
          if (tags.includes(filter)) {
            item.style.display = "block";
            this.noResults = true;
          } else {
            item.style.display = "none";
          }
        });
      } else {
        this.refs.slides.forEach((item) => {
          item.style.display = "block";
          this.noResults = true;
        });
      }
      this.carousel.update();
      this.refs.noResults.style.display = this.noResults ? "none" : "block";
    });
  }
  static getNamespace() {
    return "product-carousel";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "next", "prev", "noResults"];
  }
  onInit() {
    var _a, _b, _c, _d, _e;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) === 1) {
      this.element.classList.add("is-single");
    }
    if (((_d = (_c = this.refs) == null ? void 0 : _c.slides) == null ? void 0 : _d.length) <= 1)
      return;
    this.mountCarousel();
    this.onFilterChange();
    if ((_e = this.refs) == null ? void 0 : _e.seeMoreButton) {
      this.refs.seeMoreButton.addEventListener(
        "click",
        this.onSeeMoreButtonClick
      );
    }
    if (this.refs.select) {
      this.refs.select.addEventListener("change", this.onFilterChange);
    }
  }
  mountCarousel() {
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 32,
      lazy: true,
      navigation: {
        nextEl: this.refs.next,
        prevEl: this.refs.prev
      }
    });
  }
  onDestroy() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
  }
}
export default ProductCarouselComponent;
