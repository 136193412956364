"use strict";
import "project/general/scss/index.scss";
import "project/lazysizes";
import "project/general/js/what-input";
import "components/header";
import "components/footer";
import "components/modal";
import "components/select";
import "components/signup-form";
import "components/single-image";
import "components/inspect-modal";
import "components/accordion";
import "components/hero";
import "components/hero-standard";
import "components/hero-big";
import "components/crumbs";
import "components/cta-panel";
import "components/cta-panel-x3";
import "components/content-carousel";
import "components/we-recommend";
import "components/quick-links";
import "components/intro-area";
import "components/promo-panel";
import "components/image-gallery";
import "components/related-products";
import "components/promo-video";
import "components/testimonial";
import "components/loader";
import "components/image-with-text";
import "components/image-with-text-columns";
import "components/image-with-structured-text";
import "components/image-with-text-grid";
import "components/embedded-video";
import "components/tabs";
import "components/article-carousel";
import "components/colors-landing";
import "components/related-searches";
import "components/ugc-list";
import "components/message-modal";
import "components/project-card";
import "components/hero-with-ctas";
import "components/product-carousel";
import "components/search-suggestions";
import "components/form-with-text";
import "components/next-prev-articles";
import "components/social";
const files = require.context("project/general/svg", true, /^\.\/.*\.svg/);
files.keys().forEach(files);
