"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import SlimSelect from "slim-select";
import { eventBus } from "project/event-bus";
import { scrollService } from "project/general/js/scroll-service";
export default class CustomSelectComponent extends DcBaseComponent {
  static getNamespace() {
    return "select";
  }
  onInit() {
    var _a, _b;
    if ((_b = (_a = this.element.nextSibling) == null ? void 0 : _a.classList) == null ? void 0 : _b.contains("ss-main"))
      return;
    this.customSelect = new SlimSelect({
      select: this.element,
      class: "custom-select",
      settings: {
        showSearch: false
      }
    });
    if (this.element.id === "productColour") {
      eventBus.addListener("setColour", (colourId) => {
        this.customSelect.setSelected(colourId);
        scrollService.scrollToElementsTop(this.element.parentElement, null, -50);
      });
    }
  }
  destroy() {
    this.customSelect.destroy();
  }
}
