"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { QueryClientWrapper } from "shared/tanstack";
import { DcBaseComponent } from "@deleteagency/dc";
import { createRoot } from "react-dom/client";
import SearchSuggestionsModal from "./search-suggestions.modal";
import { SearchSuggestionsContext } from "components/search-suggestions/js/search-suggestions.context";
import { focusAndOpenKeyboard } from "project/general/helpers/focusAndOpenKeyboard";
class SearchSuggestionsComponent extends DcBaseComponent {
  static getNamespace() {
    return "search-suggestions";
  }
  static getRequiredRefs() {
    return ["searchButton", "modal"];
  }
  onInit() {
    this.root = createRoot(this.refs.modal);
    this.root.render(
      /* @__PURE__ */ React.createElement(QueryClientWrapper, null, /* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(SearchSuggestionsContext.Provider, { value: __spreadValues({}, this.options) }, /* @__PURE__ */ React.createElement(SearchSuggestionsModal, { searchButton: this.refs.searchButton }))))
    );
    this.refs.searchButton.addEventListener("click", () => {
      const input = document.querySelector(".ss-form__input");
      if (!input)
        return;
      focusAndOpenKeyboard(input, 500);
    });
  }
  onDestroy() {
    var _a;
    (_a = this.root) == null ? void 0 : _a.unmount();
  }
}
export default SearchSuggestionsComponent;
