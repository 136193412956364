"use strict";
import { useEffect } from "react";
import { Modal } from "components/modal/js/modal";
const useModalEffect = ({ modalRef, isActive, isModalAnimate, closeModalCb, enabled = true }) => {
  useEffect(() => {
    if (!modalRef.current || !enabled)
      return;
    const modal = new Modal({
      modalDomNode: modalRef.current,
      isAnimated: isModalAnimate,
      closeCb: closeModalCb
    });
    isActive ? modal.open() : modal.close();
  }, [isActive, modalRef, isModalAnimate]);
};
export { useModalEffect };
