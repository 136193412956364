"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { wrapHowTo } from "project/general/helpers/wrapText";
class RelatedSearchesComponent extends DcBaseComponent {
  static getNamespace() {
    return "related-searches";
  }
  static getRequiredRefs() {
    return ["text"];
  }
  onInit() {
    this.refs.text.forEach((item) => {
      const text = item.innerText;
      return item.innerHTML = wrapHowTo(text);
    });
  }
}
export default RelatedSearchesComponent;
