"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import Accordion from "components/accordion/js/accordion";
export default class AccordionComponent extends DcBaseComponent {
  static getNamespace() {
    return "accordion";
  }
  static getRequiredRefs() {
    return ["items"];
  }
  onInit() {
    this.accordions = [];
    this.refs.items.forEach((item) => {
      let trigger = item.querySelector(".js-accordion-trigger");
      let content = item.querySelector(".js-accordion-content");
      let accordion = new Accordion({
        rootRef: item,
        triggerRef: trigger,
        contentRef: content
      });
      this.accordions.push(accordion);
    });
  }
  destroy() {
    this.accordions.forEach((accordion) => {
      accordion.destroy();
    });
  }
}
