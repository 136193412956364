"use strict";
import { ApiClient } from "api/openapi";
const getApiClient = () => {
  const apiClientConfig = {
    BASE: window.location.origin
  };
  const apiClient = new ApiClient(apiClientConfig);
  return apiClient;
};
export { getApiClient };
