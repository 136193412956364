"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useContext, useMemo } from "react";
import { SearchSuggestionsContext } from "components/search-suggestions/js/search-suggestions.context";
import SearchSuggestionsItem from "components/search-suggestions/js/search-suggestions.item";
const SearchSuggestionsResults = React.memo((props) => {
  const { popularResultsTitle, popularProducts, noResultsTitle } = useContext(SearchSuggestionsContext);
  const showResults = props.query && props.data;
  const results = useMemo(() => showResults ? props.data : popularProducts, [showResults, props.data, popularProducts]);
  return React.createElement(
    React.Fragment,
    null,
    !showResults && React.createElement("div", { className: "ss-results__title" }, popularResultsTitle || "Popular products"),
    React.createElement("div", { className: "ss-results__list" }, results && results.length > 0 ? results.map((item) => React.createElement(SearchSuggestionsItem, __spreadValues({ key: item.url, query: props.query }, item))) : React.createElement("div", { className: "ss-results__no-results" }, noResultsTitle))
  );
});
SearchSuggestionsResults.displayName = "SearchSuggestionsResults";
export default SearchSuggestionsResults;
