"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
const MAX_ITEMS_IN_LINE = 2;
export default class Crumbs extends DcBaseComponent {
  static getNamespace() {
    return "crumbs";
  }
  static getRequiredRefs() {
    return ["list", "items", "container"];
  }
  onInit() {
    this._createDropdown();
  }
  _onDropdownClick() {
    this.refs.container.classList.toggle("open");
  }
  _createDropdown() {
    const numberOfItems = this.refs.items.length;
    if (numberOfItems <= MAX_ITEMS_IN_LINE)
      return;
    this.refs.list.classList.add("mobile");
    const desktopCrumbs = this.refs.list.cloneNode(true);
    desktopCrumbs.classList.add("desktop");
    this.refs.container.appendChild(desktopCrumbs);
    const dropdownItems = this.refs.items.slice(
      0,
      numberOfItems - MAX_ITEMS_IN_LINE
    );
    const dropdown = document.createElement("ul");
    dropdown.classList.add("crumbs__dropdown");
    dropdownItems.forEach((item) => dropdown.appendChild(item));
    const openDropdownButton = document.createElement("button");
    openDropdownButton.classList.add("crumbs__open-button");
    openDropdownButton.setAttribute("aria-label", "Open crumbs dropdown");
    this._createDotItems().forEach(
      (dot) => openDropdownButton.appendChild(dot)
    );
    this.refs.container.prepend(openDropdownButton);
    this.refs.container.prepend(dropdown);
    this.openDropdownButton = openDropdownButton;
    this.openDropdownButton.addEventListener(
      "click",
      this._onDropdownClick.bind(this)
    );
  }
  _createDotItems() {
    return Array.from({ length: 3 }).map(() => document.createElement("span"));
  }
}
