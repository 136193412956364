"use strict";
import React, { useContext } from "react";
import { SearchSuggestionsContext } from "components/search-suggestions/js/search-suggestions.context";
const MIN_CHARACTER_FOR_SEARCH = 3;
const SearchSuggestionsForm = (props) => {
  const { searchPlaceholderText } = useContext(SearchSuggestionsContext);
  const handleChange = (e) => {
    const inputValue = e.target.value;
    props.setSearchValue(inputValue);
    if (inputValue === "") {
      props.setQuery("");
    }
    if (inputValue.trim().length >= MIN_CHARACTER_FOR_SEARCH) {
      props.setQuery(inputValue);
    }
  };
  const handleClick = () => {
    props.setQuery("");
    props.setSearchValue("");
  };
  return React.createElement(
    "div",
    { className: "ss-form" },
    React.createElement(
      "div",
      { className: "form__input" },
      React.createElement(
        "svg",
        { className: "icon ss-form__icon", width: "24", height: "24" },
        React.createElement("use", { xlinkHref: "#icon-search" })
      ),
      React.createElement("input", { name: "query", type: "text", className: "ss-form__input", value: props.searchValue, placeholder: searchPlaceholderText, onChange: handleChange })
    ),
    React.createElement("button", { className: `ss-form__btn ${props.searchValue.trim() !== "" && "active"}`, onClick: handleClick })
  );
};
export default SearchSuggestionsForm;
