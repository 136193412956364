"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class V1Service {
  constructor(httpRequest) {
    __publicField(this, "httpRequest");
    this.httpRequest = httpRequest;
  }
  getUmbracoApiArticles(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/articles",
      query: {
        filter: data.filter,
        articleType: data.articleType,
        articleRootOverride: data.articleRootOverride,
        page: data.page,
        pageSize: data.pageSize
      }
    });
  }
  getUmbracoApiLocationsNearest(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/locations/nearest",
      query: {
        latitude: data.latitude,
        longitude: data.longitude
      }
    });
  }
  getUmbracoApiProducts(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/products",
      query: {
        filter: data.filter,
        mapColours: data.mapColours,
        page: data.page,
        pageSize: data.pageSize
      }
    });
  }
  postUmbracoApiProductsCompare(data = {}) {
    return this.httpRequest.request({
      method: "POST",
      url: "/umbraco/api/products/compare",
      body: data.requestBody,
      mediaType: "application/json"
    });
  }
  getUmbracoApiSearchArticles(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/search/articles",
      query: {
        query: data.query,
        page: data.page,
        pageSize: data.pageSize
      }
    });
  }
  getUmbracoApiSearchPages(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/search/pages",
      query: {
        query: data.query,
        page: data.page,
        pageSize: data.pageSize
      }
    });
  }
  getUmbracoApiSearchProducts(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/search/products",
      query: {
        query: data.query,
        page: data.page,
        pageSize: data.pageSize
      }
    });
  }
  getUmbracoApiSearchSuggestions(data = {}) {
    return this.httpRequest.request({
      method: "GET",
      url: "/umbraco/api/search/suggestions",
      query: {
        query: data.query,
        page: data.page,
        pageSize: data.pageSize
      }
    });
  }
}
