"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Pagination } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
export default class RelatedProductsComponent extends DcBaseComponent {
  static getNamespace() {
    return "related-products";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "pagination"];
  }
  onInit() {
    var _a, _b, _c, _d;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) === 1) {
      this.element.classList.add("is-single");
    }
    if (((_d = (_c = this.refs) == null ? void 0 : _c.slides) == null ? void 0 : _d.length) <= 1)
      return;
    this.mountCarousel();
  }
  mountCarousel() {
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Pagination],
      slidesPerView: 1.5,
      spaceBetween: 15,
      lazy: true,
      pagination: {
        el: this.refs.pagination,
        clickable: true
      },
      breakpoints: {
        769: {
          slidesPerView: 4,
          spaceBetween: 35
        }
      }
    });
  }
  onDestroy() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
  }
}
