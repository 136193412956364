"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Navigation, Pagination } from "swiper/modules";
import Swiper from "swiper";
import "swiper/css";
import { gsap } from "gsap";
export default class ImageGalleryComponent extends DcBaseComponent {
  static getNamespace() {
    return "image-gallery";
  }
  static getRequiredRefs() {
    return ["carousel", "slides", "next", "prev", "pagination"];
  }
  onInit() {
    var _a, _b, _c, _d;
    if (((_b = (_a = this.refs) == null ? void 0 : _a.slides) == null ? void 0 : _b.length) === 1) {
      this.element.classList.add("is-single");
    }
    if (((_d = (_c = this.refs) == null ? void 0 : _c.slides) == null ? void 0 : _d.length) <= 1)
      return;
    this.mountCarousel();
  }
  setTranslate(swiper) {
    const durationInSeconds = swiper.params.speed / 1e3;
    const slides = Object.values(swiper.slides);
    slides.map((slide, index) => {
      const offset = slide.swiperSlideOffset;
      let x = -offset;
      let y = 0;
      gsap.set(slide, {
        x,
        y,
        zIndex: swiper.slides.length - index
      });
      const opacity = Math.max(1 - Math.abs(slide.progress), 0);
      gsap.to(slide.querySelector(".js-description"), durationInSeconds, {
        opacity
      });
      if (slide.progress >= 0 && slide.progress <= 1) {
        const positionProgress = slide.progress * 100;
        const imgClipPath = "inset(0 0 0 " + positionProgress + "%)";
        gsap.to(slide.querySelector(".js-slide-picture"), durationInSeconds, {
          clipPath: imgClipPath
        });
      }
      if (slide.progress < 0 && slide.progress >= -1) {
        const positionProgress = (1 + slide.progress) * 100;
        const imgClipPath = "inset(0 " + positionProgress + "% 0 0)";
        gsap.to(slide.querySelector(".js-slide-picture"), durationInSeconds, {
          clipPath: imgClipPath
        });
      }
      if (slide.progress === 0) {
        setTimeout(() => {
          slide.style.zIndex = 100;
          swiper.el.classList.remove("has-blocked-buttons");
        }, swiper.params.speed);
      }
    });
  }
  mountCarousel() {
    const that = this;
    this.carousel = new Swiper(this.refs.carousel, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      speed: 500,
      lazy: true,
      watchSlidesProgress: true,
      virtualTranslate: true,
      effect: "myCustomTransition",
      navigation: {
        nextEl: this.refs.next,
        prevEl: this.refs.prev
      },
      pagination: {
        el: this.refs.pagination,
        clickable: true
      },
      on: {
        setTranslate() {
          const swiper = this;
          if (swiper.params.effect !== "myCustomTransition")
            return;
          swiper.el.classList.add("has-blocked-buttons");
          that.setTranslate(swiper);
        }
      }
    });
  }
  onDestroy() {
    var _a;
    (_a = this.carousel) == null ? void 0 : _a.destroy();
    this.carousel = null;
  }
}
