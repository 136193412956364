"use strict";
class YoutubePlayer {
  constructor(iframe) {
    window.onYouTubeIframeAPIReady = () => {
      window.youtubeApiReady = true;
      this.runCallbacks();
    };
    this.iframe = iframe;
    this.player = null;
    this.onYouTubeIframeAPIReadyCallbacks = [];
    this.initPlayer();
    this.insertYoutubeApiTag();
    if (this.isYoutubeApiReady()) {
      this.runCallbacks();
    }
  }
  insertYoutubeApiTag() {
    if (window.YT && window.YT.Player) {
      this.runCallbacks();
      return;
    }
    if (window == null ? void 0 : window.youtubeApiTag)
      return;
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window.youtubeApiTag = true;
  }
  initPlayer() {
    this.onYouTubeIframeAPIReadyCallbacks.push(() => {
      this.player = new window.YT.Player(this.iframe, {
        events: {
          onReady: () => this.onPlayerReadyCallback()
        }
      });
    });
  }
  onPlayerReadyCallback() {
    if (typeof this.player.playVideo === "function") {
      this.player.playVideo();
    }
  }
  pauseVideo() {
    if (typeof this.player.pauseVideo === "function") {
      this.player.pauseVideo();
    }
  }
  isYoutubeApiReady() {
    return window == null ? void 0 : window.youtubeApiReady;
  }
  runCallbacks() {
    for (let callback of this.onYouTubeIframeAPIReadyCallbacks) {
      callback();
    }
  }
}
export { YoutubePlayer };
