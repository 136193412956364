"use strict";
import React, { useMemo } from "react";
import { ReactImage } from "components/react-image/js/react-image";
export const SearchItemImage = (props) => {
  var _a;
  const imageMemoized = useMemo(() => {
    var _a2, _b;
    const imageUrl = props.imageUrl || ((_a2 = props.image) == null ? void 0 : _a2.src) || "";
    return imageUrl ? React.createElement(ReactImage, { className: "ss-item__image", imageUrl: props.imageUrl || ((_b = props.image) == null ? void 0 : _b.src) || "", alt: `${props.name} image`, mediaProperties: [
      { maxWidth: 768, imageWidth: 96, imageHeight: 96 },
      { minWidth: 769, imageWidth: 96, imageHeight: 96 }
    ] }) : React.createElement("div", { className: "ss-item__image ss-item__image-default" });
  }, [props.imageUrl, (_a = props.image) == null ? void 0 : _a.src]);
  return React.createElement(React.Fragment, null, imageMemoized);
};
