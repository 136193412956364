"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import Accordion from "components/accordion/js/accordion";
export default class Header extends DcBaseComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "headerSticky", () => {
      let start = window.scrollY;
      if (start > 80) {
        if (start > this.navScrollTop) {
          if (!this.body.classList.contains("nav-open")) {
            this.element.classList.add("is-hidden");
          }
        } else {
          this.element.classList.remove("is-hidden");
          this.element.classList.add("is-bordered");
        }
      } else {
        this.element.classList.remove("is-bordered");
      }
      this.navScrollTop = start;
    });
    __publicField(this, "toggleMobileMenu", () => {
      this.body.classList.toggle("nav-open");
    });
    __publicField(this, "openMobileSubmenu", (e) => {
      e.preventDefault();
      e.currentTarget.closest(".js-nav-item").querySelector(".js-mega-menu").classList.add("open");
    });
    __publicField(this, "closeMobileSubmenu", (e) => {
      e.currentTarget.parentNode.classList.remove("open");
      e.stopPropagation();
    });
    __publicField(this, "initAccordions", () => {
      this.refs.itemsPrimary.forEach((item) => {
        if (item.classList.contains("has-child")) {
          let secondaryNavItems = item.querySelectorAll(
            ".js-mega-menu > .js-nav-secondary > li"
          );
          secondaryNavItems.forEach((secondaryNavItem) => {
            let accordionTriggerLink = secondaryNavItem.querySelector(
              ".js-accordion-trigger"
            );
            let secondarySubnav = secondaryNavItem.querySelector(
              ".js-nav-secondary"
            );
            let accordion = new Accordion({
              rootRef: secondaryNavItem,
              triggerRef: accordionTriggerLink,
              contentRef: secondarySubnav
            });
            this.accordions.push(accordion);
          });
        }
      });
    });
  }
  static getNamespace() {
    return "header";
  }
  static getRequiredRefs() {
    return ["menuIcon", "itemsPrimary"];
  }
  onInit() {
    this.navScrollTop = 0;
    this.accordions = [];
    this.body = document.querySelector("body");
    this._addListeners();
    this.initAccordions();
  }
  _addListeners() {
    this.addListener(window, "scroll", this.headerSticky);
    this.refs.menuIcon.addEventListener("click", this.toggleMobileMenu);
    this.refs.itemsPrimary.forEach((item) => {
      if (item.classList.contains("has-child")) {
        item.querySelector(".icon").addEventListener("click", this.openMobileSubmenu);
      }
    });
    this.refs.backBtn.forEach((btn) => {
      btn.addEventListener("click", this.closeMobileSubmenu);
    });
  }
  destroy() {
    this.accordions.forEach((accordion) => accordion.destroy());
  }
}
