"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
export default class TabsComponent extends DcBaseComponent {
  constructor(...args) {
    super(...args);
    __publicField(this, "onLinkClick", (e, id) => {
      this.deactivate(this.activeId);
      this.activeId = id;
      this.activate(id);
      window.history.pushState({}, "", window.location.protocol + "//" + window.location.host + window.location.pathname + this.tabs[id].link.hash);
      e.preventDefault();
    });
    this.activeId = null;
    this.tabs = {};
  }
  static getNamespace() {
    return "tabs";
  }
  static getRequiredRefs() {
    return ["links", "contents"];
  }
  onInit() {
    if (this.refs.links && !Array.isArray(this.refs.links)) {
      this.refs.links = [this.refs.links];
    }
    if (this.refs.content && !Array.isArray(this.refs.links)) {
      this.refs.content = [this.refs.content];
    }
    this.refs.links.forEach((link) => {
      const foundContent = this.refs.contents.find((content) => `#${content.id}` === link.hash) || null;
      if (!foundContent) {
        console.log(`Missed content ref for ${link.hash}`);
      }
      this.tabs[link.hash] = {
        id: link.hash,
        link,
        content: foundContent
      };
    });
    Object.keys(this.tabs).forEach((key) => {
      this.addListener(
        this.tabs[key].link,
        "click",
        (e) => this.onLinkClick(e, key)
      );
    });
    const hash = window.location.hash;
    if (hash) {
      const tab = this.tabs[hash];
      if (tab) {
        this.reset();
        this.activeId = tab.id;
        this.activate(this.activeId);
        return;
      }
    }
    const activeId = Object.keys(this.tabs).find(
      (key) => this.tabs[key].link.classList.contains("is-active")
    );
    if (activeId) {
      this.reset();
      this.activeId = activeId;
      this.activate(this.activeId);
    }
  }
  reset() {
    Object.keys(this.tabs).forEach((key) => {
      this.deactivate(key);
    });
  }
  activate(id) {
    const tab = this.tabs[id];
    if (!tab)
      return;
    if (tab.link) {
      tab.link.classList.add("is-active");
    }
    if (tab.content) {
      tab.content.classList.add("is-active");
    }
  }
  deactivate(id) {
    const tab = this.tabs[id];
    if (!tab)
      return;
    if (tab.link) {
      tab.link.classList.remove("is-active");
    }
    if (tab.content) {
      tab.content.classList.remove("is-active");
    }
  }
}
