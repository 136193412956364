"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Modal } from "components/modal/js/modal";
import { YoutubePlayer } from "project/general/js/youtube-player";
export default class PromoVideo extends DcBaseComponent {
  static getNamespace() {
    return "promo-video";
  }
  static getRequiredRefs() {
    return ["openModal", "image"];
  }
  onInit() {
    this._checkThumbNail();
    this._addListeners();
    this.modalNode = null;
    this.player = null;
  }
  _checkThumbNail() {
    if (this.options.isThumbnail)
      return;
    const videoId = this.options.videoId;
    const thumbnailEl = this._createThumbnail(videoId);
    this.refs.image.appendChild(thumbnailEl);
  }
  _addListeners() {
    this.refs.openModal.addEventListener(
      "click",
      this._handleOpenModalClick.bind(this)
    );
    this.refs.image.addEventListener(
      "click",
      this._handleOpenModalClick.bind(this)
    );
  }
  _handleOpenModalClick() {
    if (!this.modalNode) {
      this._createVideoModal();
    }
    this._onOpenModal();
  }
  _onOpenModal() {
    this.modal.open();
  }
  _createVideoModal() {
    const template = document.querySelector("#video-modal");
    const modalContent = template.content.cloneNode(true);
    const videoId = this.options.videoId;
    const iframe = this._createIframe(videoId);
    modalContent.querySelector(".promo-video__modal-media").appendChild(iframe);
    const modalWrapper = document.createElement("div");
    modalWrapper.classList.add("modal");
    modalWrapper.appendChild(modalContent);
    this.player = new YoutubePlayer(iframe);
    this.modalNode = document.body.appendChild(modalWrapper);
    this.modal = new Modal({
      modalDomNode: this.modalNode,
      isAnimated: false,
      closeCb: () => {
        this.player.pauseVideo();
      }
    });
    this.closeModalButton = this.modalNode.querySelector(".modal__close");
    this.closeModalButton.addEventListener("click", () => this._closeModal());
  }
  _closeModal() {
    this.modal.close();
  }
  _createIframe(id) {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("allow", "autoplay");
    iframe.setAttribute("src", this._generateURLIframe(id));
    return iframe;
  }
  _generateURLIframe(id) {
    const query = "?rel=0&showinfo=0&enablejsapi=1";
    return "https://www.youtube.com/embed/" + id + query;
  }
  _createThumbnail(id) {
    const pictureEl = document.createElement("picture");
    const sourceEl = document.createElement("source");
    const img = document.createElement("img");
    sourceEl.setAttribute("type", "image/webp");
    sourceEl.setAttribute(
      "srcset",
      `https://i.ytimg.com/vi_webp/${id}/maxresdefault.webp`
    );
    img.setAttribute("src", `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`);
    img.setAttribute("alt", "YouTube video");
    sourceEl.appendChild(img);
    pictureEl.appendChild(sourceEl);
    return pictureEl;
  }
}
