"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import { Modal } from "components/modal/js/modal";
class MessageModalComponent extends DcBaseComponent {
  static getNamespace() {
    return "message-modal";
  }
  static getRequiredRefs() {
    return ["closeButton"];
  }
  onInit() {
    this.delay = this.element.getAttribute("data-delay");
    this.modal = new Modal({
      modalDomNode: this.element,
      isAnimated: false
    });
    window.onload = () => {
      setTimeout(() => {
        this.modal.open();
      }, this.delay * 1e3);
    };
    this.refs.closeButton.addEventListener("click", () => this.modal.close());
  }
}
export default MessageModalComponent;
