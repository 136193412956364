"use strict";
export const defaultMediaProperties = [
  {
    maxWidth: 768,
    imageWidth: 720
  },
  {
    minWidth: 769,
    imageWidth: 620
  }
];
